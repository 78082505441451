import {combineReducers} from "redux";

import appReducer from "./app/appReducer";
import formReducer from "./form/formReducer";
import languageReducer from "./language/languageReducer";

const rootReducer = combineReducers({
    app: appReducer,
    form: formReducer,
    language: languageReducer
});

export default rootReducer
