import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';

var bnr1 = require('./../../images/background/line.png');

const AboutUs = () => {
    const {t} = useTranslation() 

    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    }, [])

    return (
        <>
            <div className="section-full p-t30 p-b80 bg-white inner-page-padding">
                <div className="container">
                    <div className="section-content ">
                        <div className="row">
                            <div className="col-md-5 col-sm-6">
                                <div className="m-about ">
                                    <div style={{ height: 600 }}>
                                        <div className="item">
                                            <div className="ow-img">
                                                <img src={require('./../../images/wolf/about/about-1.jpg').default} alt="about" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-7 col-sm-6">
                                <div className="m-about-containt text-black p-t80">
                                    <div className="m-about-years bg-moving" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                                        <span className="text-primary large-title">Wolf Markets</span>
                                        <span className="large-title-info">{t('BANKA HARİÇ HER ŞEY')}</span>
                                    </div>
                                    <h4 className="font-weight-600 m-t40">{t('Kredi ve sermaye yönetimi 2004 yılında kuruldu. Global piyasalarda 20 yıllık tecrübeyle, yatırımcılar için karmaşık ve zor olanı, kolay hale getirdik.')}</h4>
                                    <p>{t("Wolf Markets'da, büyük olmayan yatırımcılar, küçük ve orta ölçekli yatırımcılar için kurumsal finansman konusunda uzmanız.")}</p>
                                    <p>{t('Ama banka ile aynı şeyleri yapıyor olsak da biz BANKA değiliz, sizin gibi yatırımcı, kişisel parasına ve şirket hazinesine sizin gibi değer veren insanlarız.')}</p>
                                    <div className='m-l10'>
                                        <p><AiOutlineCheckCircle size={25} className="text-primary about-icon" /> {t('Modası geçmiş geleneğe değil, yeniliğe inanıyoruz')}</p>
                                        <p><AiOutlineCheckCircle size={25} className="text-primary about-icon" /> {t('Tartışmak yerine harekete geçiyoruz.')}</p>
                                        <p><AiOutlineCheckCircle size={25} className="text-primary about-icon" /> {t('Başkalarının yalnızca engel gördüğü fırsatları biz görürüz.')}</p>
                                        <p><AiOutlineCheckCircle size={25} className="text-primary about-icon" /> {t('Bizimle birlikte olan bir yatırımcı, birlikte gelişebileceğimiz biri olur.')}</p>
                                    </div>
                                    <p>{t('Gerisi basit, hızlı ve kişisel...')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;