import {SET_LANGUAGE} from "./types";

const initialState = {
    language : "TR"
};

function languageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language,
            };
        default:
            return state;
    }
}

export default languageReducer;