import React, { useEffect } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import AllPrices from '../Elements/AllPrices';
import { useTranslation } from 'react-i18next';
var bnrimg = require('./../../images/wolf/banner/pricelist.webp');

const AllPrice = () => {
    const { t } = useTranslation()

    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

        { document.title = t("Piyasa - Wolf Markets - Benzersiz Ve Güvenilir Yatırım Partneri") }


    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={t('Tüm ürünlerin canlı fiyat akışlarını takip ederek piyasalardan sürekli haberdar olabilirsiniz.')} pagename={t('Piyasa')} bgimage={bnrimg.default} />
                <div className="section-full">
                    {/* <AllPrices /> */}
                    <div className="container" style={{ marginTop: "25px", marginBottom: "50px" }}>
                        <iframe src="https://fxpricing.com/fx-widget/market-currency-rates-widget.php?id=1,2,3,14,20,21,39,4,73&click_target=blank&theme=transparent&by-cr=28A745&sl-cr=DC3545&flags=rectangle&value_alignment=center&column=price,ask,bid,chg,chg_per,spread,time&lang=en&font=Arial, sans-serif" width="100%" height={450} style={{ border: '1px solid #eee' }} /><style type="text/css" dangerouslySetInnerHTML={{ __html: "#fx-pricing-widget-copyright{text-align: center; font-size: 13px; font-family: sans-serif; margin-top: 10px; margin-bottom: 10px; color: #9DB2BD;} #fx-pricing-widget-copyright a{text-decoration: unset; color: #BB3534; font-weight: 600;}" }} />
                    </div>
                </div>
            </div>

            <Footer />

        </>
    );
};

export default AllPrice;