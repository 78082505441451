import {SET_FORM} from "./types";

const initialState = {
    form : "gercek"
};

function formReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FORM:
            return {
                ...state,
                form: action.form,
            };
        default:
            return state;
    }
}

export default formReducer;